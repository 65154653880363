<template>
  <div class="app_con">
    <app-list :remote="remote" :props="props" :formProps="formProps" :searchQuery="searchQuery"
      :searchControl="searchControl" :apiName="apiName" :editTitle="'商品'"
      :btnFlag="{ addShow: true, editShow: false, deleteShow: false }">
      <!-- <div class="search" slot="tool">
        <el-input
          placeholder="根据标题查询"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">标题</template>
        </el-input>

        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">类别</div>
          <el-select
            filterable
            v-model="searchQuery.typeId"
            clearable
            placeholder="根据类别筛选"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div> -->
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    // let self = this;
    return {
      remote: api.common,
      apiName: "/marketUuptAddress",
      searchVal: "",
      jobList: [],
      typeList: [],
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [8, 8, 8, 8],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { num: 0 },

      props: [
        {
          label: "名称",
          prop: "name",
          align: "center",
          width: 400,
        },
        {
          label: "电话",
          prop: "pubusermobile",
          align: "center",
        },

        {
          label: "城市",
          prop: "cityName",
          align: "center",
          default: "南宁市",
          disabled: true
        },

        {
          label: "街道",
          prop: "countyName",
          align: "center",
          default: "西乡塘区"
        },

        {
          label: "地址",
          prop: "fromAddress",
          align: "center",
        },

        {
          label: "详细地址",
          prop: "fromUsernote",
          align: "center",
        },




        {
          label: "是否默认",
          prop: "isDefault",
          type: 'html',
          formatter: function (row) {
            let code = "";
            if (row.isDefault == 0) {
              code = "<span class='font-blue'>默认地址</span>"
            }
            else {
              code = "<span>否</span>"
            }
            return code;
          },
        },


        {
          label: "经度",
          prop: "lng",
          align: "center",
        },
        {
          label: "纬度",
          prop: "lat",
          align: "center",
        },

        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () { },
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function () { },
              },
            ];

            return buttonList;
          },
        },
      ],
      formProps: [

        {
          label: "名称",
          type: "input",
          prop: "name",
        },
        {
          label: "电话",
          type: "input",
          prop: "pubusermobile",
        },
        {
          label: "城市",
          type: "input",
          prop: "cityName",
          default: "南宁市",
          disabled:true
        },
        {
          label: "区域",
          type: "select",
          prop: "countyName",
          default: "西乡塘区",
          selectData: [
            { label: '兴宁区', value: '兴宁区' },
            { label: '青秀区', value: '青秀区' },
            { label: '江南区', value: '江南区' },
            { label: '西乡塘区', value: '西乡塘区' },
            { label: '良庆区', value: '良庆区' },

            { label: '邕宁区', value: '邕宁区' },
            { label: '武鸣区', value: '武鸣区' },
          ]
        },

        {
          label: "详细地址",
          type: "input",
          prop: "fromUsernote",

        },

        {
          label: "启用",
          type: "radio",
          prop: "isDefault",
          default: 0,
          selectData: [
            { label: "默认", value: 0 },
            { label: "否", value: 1 },
          ],
        },

        {
          label: "经度",
          type: "input",
          prop: "lng",
          default:"108.32273"
        },
        {
          label: "纬度",
          type: "input",
          prop: "lat",
          default:"22.84270"
        },

      ],
    };
  },
  created() { },
  methods: {
    //同步默认商品到商户

    async offline(id) {
      this.$confirm("此操作将用户强制下线, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await api.common
            .commonGet({
              apiName: "/system/monitor/activeUser/offline",
              id: id,
            })
            .then((res) => {
              if (res.code == 200) {
                this.searchQuery.num++;
                this.$message({ message: res.message, type: "success" });
              } else {
                this.$message(res.message);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
